import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { ReactNode } from "react";
import {
  heading200,
  heading300,
  paragraphTextMedium,
  paragraphTextSmall,
} from "../stitches/combinedStyles";
import { styled } from "../stitches/stitches.config";

const heading = `Next Steps`;

const BasicFormTemplate = ({
  title,
  byline,
  image,
  children,
}: {
  title: string;
  byline: string;
  image?: IGatsbyImageData;
  children: ReactNode;
}) => {
  return (
    <Container>
      <TextContent>
        <Heading>{heading}</Heading>
        <Title>{title}</Title>
        <Byline>{byline}</Byline>
        {image && (
          <ImageWrapper>
            <GatsbyImage image={image} alt="" />
          </ImageWrapper>
        )}
      </TextContent>
      <FormWrapper>{children}</FormWrapper>
    </Container>
  );
};

export default BasicFormTemplate;

const FormWrapper = styled("div", {
  width: "100%",
  margin: "auto",
  backgroundColor: "$blue100",
  padding: "16px 0 50px 0",
  "@lg": {
    backgroundColor: "$transparent",
    maxWidth: "none",
    textAlign: "center",
    position: "relative",
    borderRadius: 16,
  },
});

const Container = styled("div", {
  width: "100%",
  margin: "auto",
  maxWidth: 960,
  "@lg": {
    width: "90%",
  },
});
const TextContent = styled("div", {
  width: "90%",
  margin: "auto",
  maxWidth: 600,
  "@lg": {
    maxWidth: "none",
  },
});

const Title = styled("h3", {
  ...heading200,
  margin: "20px 0",
  "@lg": {
    heading300,
  },
});
const Byline = styled("p", {
  ...paragraphTextMedium,
});
const Heading = styled("h6", {
  ...paragraphTextSmall,
  textTransform: "uppercase",
  color: "$blue100",
  display: "none",
  marginBottom: 0,
  "@lg": {
    display: "block",
  },
});

const ImageWrapper = styled("div", {
  width: "100%",
  display: "none",
  justifyContent: "center",
  "@lg": {
    display: "flex",
  },
});
