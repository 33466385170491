import React, { ReactNode } from "react";
import {
  date,
  heading150,
  paragraphTextSmall,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";

const privacyText = `By submitting this form you agree to our terms and conditions and our Privacy Policy which explains how we may collect, use and disclose your personal information including to third parties.`;

export const PrivacyDisclaimer = ({ mobile }: { mobile: boolean }) => (
  <PrivacyText mobile={mobile}>{privacyText}</PrivacyText>
);

const PrivacyText = styled("p", {
  ...date,
  fontFamily: "$opensans",
  fontWeight: 400,
  color: "$white",
  textAlign: "center",
  width: "90%",
  margin: "auto",
  marginTop: 16,
  opacity: 0.6,
  maxWidth: 600,
  "@lg": {
    maxWidth: "none",
    fontSize: 16,
    lineHeight: "28px",
    color: "rgba(90, 113, 132, 1)",
    textAlign: "left",
    width: "100%",
    marginBottom: 32,
  },
  variants: {
    mobile: {
      true: {
        display: "block",
        "@lg": {
          display: "none",
        },
      },
      false: {
        display: "none",
        "@lg": {
          display: "block",
        },
      },
    },
  },
});

interface Props {
  htmlFor: string;
  required?: boolean;
  children: ReactNode;
}

export const FormLabel = ({ htmlFor, required = false, children }: Props) => {
  return (
    <LabelContainer>
      <Label htmlFor={htmlFor}>
        {children}
        {required && "*"}
      </Label>
    </LabelContainer>
  );
};

const LabelContainer = styled("div", {
  marginBottom: 8,
});

const Label = styled("label", {
  ...paragraphTextSmall,
  fontWeight: 600,
});

export const FormWrapper = styled("div", {
  backgroundColor: "$blue100",
  padding: "16px 0",
  "@lg": {
    backgroundColor: "$transparent",
  },
});

export const FormContainer = styled("form", {
  backgroundColor: "$white",
  padding: "24px 15px",
  borderRadius: 8,
  width: "95%",
  margin: "auto",
  maxWidth: 600,
  "@lg": {
    maxWidth: 960,
    padding: 64,
    width: "100%",
    paddingBottom: 100,
  },
});

export const FormHubspotWrapper = styled("div", {
  backgroundColor: "$white",
  padding: "24px 15px",
  borderRadius: 8,
  width: "95%",
  margin: "auto",
  maxWidth: 600,
  "@lg": {
    maxWidth: 960,
    padding: 64,
    width: "100%",
    paddingBottom: 100,
  },
});

export const BasicElementsContainer = styled("div", {
  "@lg": {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
});

export const InputWrapper = styled("div", {
  textAlign: "left",
  marginBottom: 32,
  position: "relative",
  "@lg": {
    width: "48%",
  },
  variants: {
    fullWidth: {
      true: {
        width: "100%",
      },
    },
  },
});

export const StyledInput = styled("input", {
  ...paragraphTextSmall,
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: 600,
  color: "rgba(24, 59, 86, 1)",
  padding: "10px 16px",
  border: "1px solid rgba(195, 202, 217, 1)",
  borderRadius: 8,
  width: "100%",
});

export const FormTitle = styled("h4", {
  ...heading150,
  textAlign: "center",
  marginTop: 0,
  marginBottom: 22,
  "@lg": {
    display: "none",
  },
});

export const SubmitButton = styled("button", {
  fontSize: 18,
  fontWeight: 700,
  fontFamily: "$opensans",
  backgroundColor: "$blue100",
  color: "$white",
  padding: "13px 0",
  width: "100%",
  border: 0,
  borderRadius: 8,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "$blue150",
  },
  "@lg": {
    width: "auto",
    padding: "13px 44px",
    float: "left",
  },
  variants: {
    success: {
      true: {
        backgroundColor: "#30c964",
        cursor: "not-allowed",
        "&:hover": {
          backgroundColor: "#30c964",
        },
      },
      false: {},
    },
    failure: {
      true: {
        cursor: "not-allowed",
        backgroundColor: "#ff0000",
        "&:hover": {
          backgroundColor: "#ff0000",
        },
      },
      false: {},
    },
  },
    "@media(max-width: 1000px)": {
    marginTop: 50,
  },
});

export const ErrorMessage = styled("span", {
  position: "absolute",
  bottom: -18,
  left: 17,
  ...date,
  color: "#ff0000",
});

export const StyledSelect = styled("select", {
  ...paragraphTextSmall,
  fontSize: 14,
  lineHeight: "20px",
  fontWeight: 600,
  color: "rgb(24, 59, 86)",
  padding: "10px 16px",
  border: "1px solid rgba(195, 202, 217, 1)",
  borderRadius: 8,
  width: "100%",
  WebkitAppearance:"none",
  appearance: "none",
  position: "absolute",
  zIndex: 101,
  backgroundColor: "transparent",
});

export const StyledSelectWrapper = styled("div", {
  position: "relative",
  "&::after": {
    content: "'\u25BE'",
    fontSize: "28px",
    fontWeight:'bold',
    top: 5,
    right: 16,
    position: "absolute",
    zIndex: 100,
    color:"#e1e1e1",
    transform: "scaleY(0.7)",
    color:"#5a7184",
  },
});

