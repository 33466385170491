import React from "react";
import WarrantyForm from "../components/basicForm/WarrantyForm";
import Layout from "../components/Layout";
import { PrivacyDisclaimer } from "../components/shared/FormStyles";
import BasicFormTemplate from "../templates/BasicFormTemplate";

const seo = {
  title: "Warranty Registration ",
  description: "Register your warranty for your Plastometrex products",
};

const title = `Warranty Registrations`;
const byline = `In order activate you warranty please fill in your details below`;

const WarrantyRegistrationPage = () => {
  return (
    <Layout seo={seo} backgroundColorUnderneath="white">
      <BasicFormTemplate title={title} byline={byline}>
        <WarrantyForm />
        <PrivacyDisclaimer mobile />
      </BasicFormTemplate>
    </Layout>
  );
};

export default WarrantyRegistrationPage;
